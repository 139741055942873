import React, { useEffect, useState } from 'react';
import { supabase } from './Components/Authentication/utils';
import './App.css';
import NBA_GPT from './GPTennis';
import { ChakraProvider } from '@chakra-ui/react';
import { Analytics } from '@vercel/analytics/react';

function App() {

  return (
    <>
    <ChakraProvider>
      <NBA_GPT />
    </ChakraProvider>
    <Analytics />
    </>

  );
}

export default App;