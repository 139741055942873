// ColorModeSwitcher.js
import { IconButton, useColorMode } from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';

const ColorModeSwitcher = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const isDarkMode = colorMode === 'dark';

  return (
    <IconButton
      zIndex="1"
      aria-label="Toggle dark mode"
      icon={isDarkMode ? <SunIcon /> : <MoonIcon />}
      onClick={toggleColorMode}
      isRound
    />
  );
};

export default ColorModeSwitcher;