import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { VegaLite } from 'react-vega';
import {
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Container,
  Box,
  Code,
  Heading,
  SimpleGrid,
  Link,
  Spinner,
  Flex,
  TableContainer,
  Tfoot,
  useColorMode,
  useColorModeValue,
  VStack
} from '@chakra-ui/react';
import './NBA_GPT.css';
import './prism-nba-theme.css';
import Prism from 'prismjs';
import 'prismjs/components/prism-sql';
import './Gradient.css';
import ColorModeSwitcher from './ColorModeSwitcher';
import { supabase } from './Components/Authentication/utils';


// Development
// const API_BASE = "https://ianclim--gptennis-create-app-dev.modal.run";

// Production
const API_BASE = "https://ianclim--gptennis-create-app.modal.run";

const VEGA_LITE_TYPES_MAP = {
  int: 'quantitative',
  float: 'quantitative',
  str: 'nominal',
  bool: 'nominal',
  date: 'temporal',
  time: 'temporal',
  datetime: 'temporal',
};

const ColorModeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Button onClick={toggleColorMode} ml={2}>
      Toggle {colorMode === 'light' ? 'Dark' : 'Light'}
    </Button>
  );
};


const isImageUrl = (url) => {
  if (!url) return false;
  return url.match(/\.(jpeg|jpg|gif|png|svg)$/) !== null;
};


const createVizDataDict = (columnNames, columnTypes, results) => {
  const data = {
    fields: [],
    total_rows: results.length,
  };
  columnNames.forEach((columnName, i) => {
    data.fields.push({
      name: columnName,
      type: VEGA_LITE_TYPES_MAP[columnTypes[i]] || 'nominal',
    });
  });
  results.slice(0, 1).forEach((r, i) => {
    columnNames.forEach((columnName, j) => {
      data.fields[j].sample_value = r[columnName];
    });
  });
  return data;
};

const NBA_GPT = () => {
  const [query, setQuery] = useState('');
  const [querySubmitted, setQuerySubmitted] = useState(false);
  const [sql, setSql] = useState('');
  const [finalSql, setFinalSql] = useState('');
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState([]);
  const [promptId, setPromptId] = useState(null);
  const [feedback, setFeedback] = useState({
    rating: '', // 'thumbsUp' or 'thumbsDown'
    comment: '', // optional comment from user
  });
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);



  // Light/dark mode colors
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const backgroundColor = useColorModeValue('white', '#22262f');
  const textColor = useColorModeValue('black', 'white');
  const headerTextColor = useColorModeValue('black', 'white');
  const codeBackgroundColor = useColorModeValue('#f5f5f5', '#22262f');
  const codeTextColor = useColorModeValue('#000000', '#ffffff');

  const messages = ['Processing your request', 'Searching over 14,000 games', 'Almost there'];

  useEffect(() => {
    let messageIndex = 0;

    if (isLoading) {
      setLoadingMessages([messages[messageIndex++]]);
      const intervalId = setInterval(() => {
        if (messageIndex < messages.length) {
          setLoadingMessages((prevMessages) => [...prevMessages, messages[messageIndex]]);
          messageIndex++;
        }
      }, 7000);

      return () => clearInterval(intervalId);
    } else {
      setLoadingMessages([]);
    }
  }, [isLoading]);

  useEffect(() => {
    Prism.highlightAll();
  }, [sql]);

  const handleSubmit = async (prompt) => {
    const inputQuery = prompt || query;
    setIsLoading(true);
    setQuerySubmitted(true);
    setError(null);

    try {
      const textToSqlRequest = axios.post(`${API_BASE}/text_to_sql`, { natural_language_query: inputQuery, user_id: 'e33c4c8a-182e-41b2-990c-f9a7d4fad697' })
        .then(response => {
          setFinalSql(response.data.sql_query);
          setResult(response.data.result);
          setPromptId(response.data.id);

          // Generate final summary of data based on SQL result
          axios.post(`${API_BASE}/generate_summary`, { response: response.data.result })
            .then(summaryResponse => {
              if (summaryResponse.status === 200) {
                setSummary(summaryResponse.data.summary);
              }
            })
            .catch(error => {
              // handle generate_summary error
              console.error(error);
            });
        })
        .catch(error => {
          setError("Sorry about that, we couldn't find relevant stats in response to your question. We've logged this error on our servers. Please try another query.\n\n Prompt writing tips: Use players' full names and be as specific as possible. \n\n If you're still having trouble, shoot us a message at ianlim@stanford.edu.");
        });

      const initialSummaryRequest = axios.post(`${API_BASE}/generate_initial_summary`, { natural_language_query: inputQuery })
        .then(response => {
          setSummary(response.data.summary);
        })
        .catch(error => {
          console.error(error);
        });

      // Wait for both requests to complete
      await Promise.allSettled([textToSqlRequest, initialSummaryRequest]);

    } catch (error) {
      // handle network error
      setError("Sorry about that, we couldn't find relevant stats in response to your question. We've logged this error on our servers. Please try another query.\n\n Prompt writing tips: Use players' full names and be as specific as possible. \n\n If you're still having trouble, shoot us a message at ianlim@stanford.edu.");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle feedback change
  const handleFeedbackChange = (e) => {
    setFeedback({
      ...feedback,
      [e.target.name]: e.target.value,
    });
  };

  const handleFeedbackSubmit = async () => {
    // Only send feedback if a rating was given
    if (feedback.rating) {
      // Endpoint to send feedback
      const API_FEEDBACK = `${API_BASE}/response_feedback`;

      const response = await axios.post(API_FEEDBACK, {
        prompt_id: promptId,
        feedback: feedback.rating,
        comment: feedback.comment,
      });

      if (response.status === 200) {
        // Clear feedback after successful submission
        setFeedback({
          rating: '',
          comment: '',
        });
        setFeedbackSubmitted(true);
      } else {
        // Handle error
        console.error('Feedback submission failed:', response);
      }
    }
  };

  const setPrompts = [
    "How has Sabalenka performed against top 10 players in previous matches?",
    "How has Ons Jabeur performed against Aryna Sabalenka in previous match ups?",
    "Compare the head-to-head record between Elina Svitolina and Markéta Vondroušová",
    "Show me Medvedev in previous Wimbledon matches."
];


  const bettingSources = [
    { name: "Centrebet", odds: "cb" },
    { name: "Gamebookers", odds: "gb" },
    { name: "Interwetten", odds: "iw" },
    { name: "Sportingbet", odds: "sb" },
    { name: "Bet365", odds: "b365" },
    { name: "Bet&Win", odds: "b & w" },
    { name: "Expekt", odds: "ex" },
    { name: "Pinnacles Sports", odds: "ps" },
    { name: "Unibet", odds: "ub" },
    { name: "Ladbrokes", odds: "lb" },
    { name: "Stan James", odds: "sj" },
    { name: "Max Odds", odds: "max" },
    { name: "Avg Odds", odds: "avg" },
  ];

  return (
    <>
      <Flex position="fixed" top="1rem" right="1rem" zIndex="1">
        <ColorModeSwitcher />
      </Flex>
      <Container maxW="full" minheight="100vh">
        <Box mt={8} padding={4}>
          <Text fontSize="4xl" fontWeight="bold" mb={4}>
            <Link className='nba-gpt-link' href="/" cursor="pointer">🎾 GPTennis </Link>
            <sup>
              <Text as="span" color="#6897fc" fontSize='lg'>&nbsp; BETA</Text>
            </sup>
          </Text>
          <Input
            type="text"
            placeholder="Ask anything..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
            size="md"
            variant="filled"
            mb={4}
          />
          {/* {
          !querySubmitted && !result && (
            <Button
              colorScheme="blue"
              fontWeight="bold"
              size="md"
              mb={4}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )
        } */}

          {!querySubmitted && !result && (
            <Box mt={8}>
              <Heading as="h2" size="lg" mb={4}>
                What's trending 🔥
              </Heading>
              <SimpleGrid columns={[1, null, 4]} spacing={4}>
                {setPrompts.map((prompt, index) => (
                  <Box key={index}>
                    <Text>{prompt}</Text>
                    <Button
                      colorScheme="blue"
                      fontWeight="bold"
                      size="sm"
                      mt={2}
                      onClick={() => {
                        setQuery(prompt);
                        handleSubmit(prompt);
                      }}
                    >
                      Use this prompt
                    </Button>
                  </Box>
                ))}
              </SimpleGrid>
            </Box>
          )}
          {summary && (
            <Box
              mt={4}
              p={4}
              borderRadius="md"
              borderWidth="1px"
              bg={backgroundColor}
              borderColor={borderColor}
              boxShadow="sm"
              mb={10}
            >
              <Heading as="h2" size="md" mb={2}>
                Summary
              </Heading>
              <Text>
                {summary}
              </Text>
            </Box>
          )}
          {error && (
            <Box
              role="alert"
              status="error"
              borderRadius="md"
              bg="red.50"
              borderWidth="1px"
              borderColor="red.300"
              p={4}
              mb={4}
            >
              <Text color="red.800" fontWeight="bold">
                Error:
              </Text>
              <Text color="red.800">{error}</Text>
            </Box>
          )}
          {result && (
            <Box mt={4}>
              <Box mb={4}>
                {(() => {
                  const uniqueImages = new Set();
                  const headshotElements = [];
                  const logoElements = [];

                  result.results.forEach((row, rowIndex) => {
                    Object.keys(row).forEach((key) => {
                      if (key.toLowerCase().includes("headshot") && isImageUrl(row[key]) && !uniqueImages.has(row[key])) {
                        uniqueImages.add(row[key]);
                        headshotElements.push(
                          <img
                            key={`${key}-${rowIndex}`}
                            src={row[key]}
                            alt={key}
                            style={{ maxWidth: '200px', maxHeight: '200px', marginRight: '10px', marginBottom: '10px' }}
                          />
                        );
                      }

                      if (key.toLowerCase().includes("logo") && isImageUrl(row[key]) && !uniqueImages.has(row[key])) {
                        uniqueImages.add(row[key]);
                        logoElements.push(
                          <img
                            key={`${key}-${rowIndex}`}
                            src={row[key]}
                            alt={key}
                            style={{ maxWidth: '200px', maxHeight: '200px', marginRight: '10px', marginBottom: '10px' }}
                          />
                        );
                      }
                    });
                  });

                  let imageElements = headshotElements.length > 0 ? headshotElements : logoElements;

                  return (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {imageElements}
                    </div>
                  );
                })()}
              </Box>

              {('winner' in result.results[0] && 'loser' in result.results[0]) || ('w_rank' in result.results[0] && 'l_rank' in result.results[0]) || ('w_sets' in result.results[0] && 'l_sets' in result.results[0])
                ? result.results.map((match, matchIndex) => (
                  <Box overflowX="auto" border="1px solid" borderRadius="md" bg={backgroundColor} borderColor={borderColor} key={matchIndex} mb={4} p={4}>
                    <Heading as="h4" size="md">{match.tournament} | {match.round}</Heading>
                    <Text fontSize="lg">{new Date(match.date).toLocaleDateString()}</Text>
                    {/* <Text fontSize="lg">{match.date}</Text> */}
                    <Flex justify="space-between" my={4}>
                      <Box>
                        <Text fontWeight="bold">{match.winner} (Rank: {match.w_rank})</Text>
                        <Text>Sets won: {match.w_sets}</Text>
                      </Box>
                      <Box>
                        <Text fontWeight="bold">{match.loser} (Rank: {match.l_rank})</Text>
                        <Text>Sets won: {match.l_sets}</Text>
                      </Box>
                    </Flex>
                    <Table variant="simple" mt={4}>
                      <Thead>
                        <Tr>
                          <Th></Th>
                          {[1, 2, 3, 4, 5].map((set) => match[`w${set}`] !== undefined && match[`l${set}`] !== undefined ? (
                            <Th key={set}>Set {set}</Th>
                          ) : null)}
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>Winner Games</Td>
                          {[1, 2, 3, 4, 5].map((set) => match[`w${set}`] !== undefined ? (
                            <Td key={set}>{match[`w${set}`]}</Td>
                          ) : null)}
                        </Tr>
                        <Tr>
                          <Td>Loser Games</Td>
                          {[1, 2, 3, 4, 5].map((set) => match[`l${set}`] !== undefined ? (
                            <Td key={set}>{match[`l${set}`]}</Td>
                          ) : null)}
                        </Tr>
                      </Tbody>
                    </Table>
                    <Text fontSize="lg" mt={4}>Betting Odds:</Text>
                    {bettingSources.map((source) => (
                      match[`${source.odds}_w`] !== undefined && match[`${source.odds}_l`] !== undefined ? (
                        <Text key={source.name}>{source.name} - Winner: {match[`${source.odds}_w`]} | Loser: {match[`${source.odds}_l`]}</Text>
                      ) : null
                    ))}
                  </Box>
                ))
                : (
                  <Box overflowX="auto" border="1px solid" borderRadius="md" bg={backgroundColor} borderColor={borderColor}>
                    <Table variant="simple" mt={4}>
                      <Thead>
                        <Tr>
                          {result.column_names.map((name, index) => (
                            <Th key={index} color={headerTextColor}>{name}</Th>
                          ))}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {result.results.map((row, rowIndex) => (
                          <Tr key={rowIndex}>
                            {result.column_names.map((name) => (
                              <Td key={name}>
                                {name.toLowerCase().includes("percentage")
                                  ? (parseFloat(row[name]) * 100).toFixed(2) + "%"
                                  : name.toLowerCase().includes("date")
                                    ? new Date(row[name]).toLocaleDateString()
                                    : typeof row[name] === "decimal"
                                      ? typeof row[name] + parseFloat(row[name]).toFixed(2)
                                      : row[name]}
                              </Td>
                            ))}
                          </Tr>
                        ))}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          {result.column_names.map((name, index) => (
                            <Th key={index}>{name}</Th>
                          ))}
                        </Tr>
                      </Tfoot>
                    </Table>
                  </Box>
                )
              }
            </Box>
          )}
          {isLoading && (
            <Flex alignItems="center" justifyContent="center" flexDirection="column" mt={4}>
              <Spinner size="xl" />
              {loadingMessages.map((message, index) => (
                <Text key={index} mt={2}>{message}</Text>
              ))}
            </Flex>
          )}
          {result && (
            <Box mt={4}>
              {feedbackSubmitted ? (
                <Text>Thanks for your feedback!</Text>
              ) : (
                <>
                  <Button
                    mr={4}
                    colorScheme={feedback.rating === 'thumbsUp' ? 'green' : 'gray'}
                    onClick={() => setFeedback({ ...feedback, rating: 'thumbsUp' })}
                  >
                    👍
                  </Button>
                  <Button
                    mr={4}
                    colorScheme={feedback.rating === 'thumbsDown' ? 'red' : 'gray'}
                    onClick={() => setFeedback({ ...feedback, rating: 'thumbsDown' })}
                  >
                    👎
                  </Button>
                  {feedback.rating === 'thumbsDown' && (
                    <Input
                      type="text"
                      placeholder="What went wrong?"
                      name="comment"
                      value={feedback.comment}
                      onChange={handleFeedbackChange}
                    />
                  )}
                  <Button onClick={handleFeedbackSubmit}>Submit Feedback</Button>
                </>
              )}
            </Box>
          )}

          {finalSql && (
            <Box mb={4} maxWidth="100%" mt={10}>
              <Code
                display="block"
                whiteSpace="pre-wrap"
                wordBreak="break-all"
                overflowWrap="break-all"
                style={{ backgroundColor: codeBackgroundColor, color: codeTextColor }}
                className="language-sql"
                p={4}
                border="1px solid"
                borderRadius="md"
                borderColor={borderColor}
              >
                {finalSql ? finalSql : sql}
              </Code>
            </Box>
          )}

          {/* {vegaLiteSpec && (
            <VegaLite spec={vegaLiteSpec} data={{ table: result.results }} />
          )} */}
        </Box>
      </Container>
    </>
  )
};

export default NBA_GPT;